import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';

import { useMount } from '../../../../../shared/hooks/useMount';
import { getYourGuidInfo, CachedToursData } from '../../../../../shared/utils/api/getYourGuidInfo';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';
import StarRating from '../../../../../shared/components/StarRating/StarRating';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { TourPictureDto } from '../../../../../shared/models/getYourGuideDto';

import './get-your-guide-slide.scss';

const LOADING_ITEMS_COUNT = 6;
const RATING_PRECISION = 10;

const GetYourGuideSlide = ({ city, isActive }: Props) => {
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const toursDataRef = useRef<CachedToursData>();
  const errorRef = useRef<string>();

  useMount(() => {
    if (city) {
      loadDataFromServer();
    }
  });

  const loadDataFromServer = async () => {
    setComponentState(toursDataRef.current === undefined ? 'Loading' : 'Reloading');
    try {
      const response = await getYourGuidInfo(city, (toursDataRef.current?.currentPage ?? 0) + 1);
      // eslint-disable-next-line functional/immutable-data
      toursDataRef.current = response;
      setComponentState('LoadedSuccessful');
    } catch (errorMessage) {
      // eslint-disable-next-line functional/immutable-data
      errorRef.current = errorMessage as string;
      setComponentState('Error');
    }
  };

  const getTourImage = (pictures: readonly TourPictureDto[]) => {
    if (pictures && pictures.length > 0) {
      return pictures[0].url.replace('[format_id]', '21');
    }
    return '/images/no_image.png';
  };

  const handleLinkClick = (e: React.SyntheticEvent) => {
    if (!isActive) {
      e.preventDefault();
    }
  };

  const formatRating = (value: number): string => {
    const rating = (Math.round(value * RATING_PRECISION) / RATING_PRECISION).toString();
    if (rating.length === 1) {
      return `${rating}.0`;
    }
    return rating;
  };

  const formatPrice = (value: number): string => {
    const price = value.toString();
    const priceParts = price.split('.');
    if (priceParts.length === 1) {
      return `${priceParts[0]}.00`;
    }
    if (priceParts[1].toString().length === 1) {
      return `${priceParts[0]}.${priceParts[1]}0`;
    }
    return value.toString();
  };

  const getPriceDetails = (priceDescription: 'individual' | 'group') => {
    if (priceDescription === 'individual') {
      return 'per person';
    }
    if (priceDescription === 'group') {
      return 'per group up to 4';
    }
    return priceDescription;
  };

  return (
    <section
      className={classNames('get-your-guide-slide', {
        'get-your-guide-slide--active': isActive,
      })}
    >
      <h2 className="get-your-guide-slide__title">Get your guide</h2>
      <div className="get-your-guide-slide__description">
        Find, compare, and book sightseeing tours, attractions, excursions, things to do from around the world.
      </div>
      <ul
        className={classNames('get-your-guide-slide__list', {
          'get-your-guide-slide__list--loading': componentState === 'Loading',
          'get-your-guide-slide__list--reloading': componentState === 'Reloading',
        })}
      >
        {componentState === 'Loading' && (
          <>
            {[...Array(LOADING_ITEMS_COUNT)].map((_, index) => (
              <li className="get-your-guide-slide__item get-your-guide-slide__item--loading" key={`item${index}`} />
            ))}
          </>
        )}
        {(componentState === 'LoadedSuccessful' || componentState === 'Reloading') &&
          toursDataRef.current !== undefined && (
            <>
              {toursDataRef.current.tours.map((tour) => (
                <li className="get-your-guide-slide__item" key={tour.tour_id}>
                  <a
                    href={tour.url}
                    className="get-your-guide-slide__link"
                    title=""
                    rel="nofollow noreferrer"
                    target="_blank"
                    onClick={handleLinkClick}
                  >
                    <div
                      className="get-your-guide-slide__image-box"
                      style={{ backgroundImage: `url(${getTourImage(tour.pictures)})` }}
                    />
                    <AnimateHeight duration={350} height={isActive ? 'auto' : 0}>
                      <div className="get-your-guide-slide__tour-title">{tour.title}</div>
                      <div className="get-your-guide-slide__tour-details">
                        {tour.durations && tour.durations.length > 0 && (
                          <>
                            {tour.durations[0].duration} {tour.durations[0].unit}
                            {tour.durations[0].duration > 1 ? 's' : ''}
                          </>
                        )}
                        {tour.has_pick_up && ' • Pickup available'}
                      </div>
                      {tour.overall_rating !== undefined && tour.overall_rating > 0 && (
                        <div className="get-your-guide-slide__rating">
                          <StarRating value={tour.overall_rating} className="get-your-guide-slide__stars" />
                          <span className="get-your-guide-slide__rating-value">
                            {formatRating(tour.overall_rating)}
                          </span>
                        </div>
                      )}
                      {/* {tour.number_of_ratings > 0 && (
                        <div className="get-your-guide-slide__reviews">
                          {tour.number_of_ratings} review{tour.number_of_ratings > 1 ? 's' : ''}
                        </div>
                      )} */}
                      {tour.price !== undefined && tour.price.values.amount > 0 && (
                        <div className="get-your-guide-slide__price">
                          <span className="get-your-guide-slide__price-value">
                            From ${formatPrice(tour.price.values.amount)}
                          </span>{' '}
                          <span className="get-your-guide-slide__price-description">
                            {getPriceDetails(tour.price.description)}
                          </span>
                        </div>
                      )}
                    </AnimateHeight>
                  </a>
                </li>
              ))}
              {isActive &&
                toursDataRef.current.currentPage * toursDataRef.current.limit < toursDataRef.current.totalCount && (
                  <li className="get-your-guide-slide__item get-your-guide-slide__item--button-group">
                    <button
                      type="button"
                      onClick={loadDataFromServer}
                      className={classNames('get-your-guide-slide__load-more-btn', {
                        'get-your-guide-slide__load-more-btn--loading': componentState === 'Reloading',
                      })}
                    >
                      <SvgIcon name="spinner" className="get-your-guide-slide__load-icon" />
                      Load more
                    </button>
                  </li>
                )}
            </>
          )}
      </ul>
    </section>
  );
};

type Props = {
  readonly city: string;
  readonly isActive?: boolean;
};

export default GetYourGuideSlide;
