import React from 'react';

import { isProductionEnvironment } from '../../../../../shared/utils/isProductionEnvironment';

import './pin-screen.scss';

const PinScreen = ({ code }: Props) => {
  return (
    <aside className="pin-screen__box">
      <img src="/images/logo.png" alt="Home guide" className="pin-screen__logo" width={116} height={42} />
      <section className="pin-screen">
        <div className="pin-screen__label">Link Code</div>
        <div className="pin-screen__pin">{code}</div>
        <a
          href={isProductionEnvironment() ? process.env.REACT_APP_HOST_PROD : process.env.REACT_APP_HOST_DEV}
          className="pin-screen__button"
          title="Open the host application"
          target="_blank"
          rel="noreferrer"
        >
          Manage your home
        </a>
      </section>
      <div className="pin-screen__alcove-label alcove-label">by Alcove</div>
    </aside>
  );
};

type Props = {
  code: string;
};

export default PinScreen;
