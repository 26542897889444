import { STORAGE_KEY } from './storageKey';

export const getLastHomeGuid = (): string | undefined => {
  const rawSavedHomes = window.localStorage.getItem(STORAGE_KEY);
  if (!rawSavedHomes) {
    return undefined;
  }
  try {
    const savedHomes = JSON.parse(rawSavedHomes);
    if (Array.isArray(savedHomes)) {
      return savedHomes[savedHomes.length - 1];
    }
    return undefined;
  } catch (error) {
    console.warn('Error during getting last home guid', error);
    return undefined;
  }
};
