import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';

import { Faq } from '../../../../../shared/models/installationDetailsDto';
import './faq-slide.scss';

const FaqSlide = ({ faq, isActive }: Props) => {
  const [faqActiveIndex, setFaqActiveIndex] = useState<readonly number[]>([]);

  const handleActiveIndexChange = (e: React.SyntheticEvent, index: number) => {
    e.preventDefault();

    if (!isActive) {
      return;
    }
    if (faqActiveIndex.includes(index)) {
      setFaqActiveIndex((v) => v.filter((item) => item !== index));
      return;
    }
    setFaqActiveIndex((v) => [...v, index]);
  };

  return (
    <section
      className={classNames('faq-slide', {
        'faq-slide--active': isActive,
      })}
    >
      <h2 className="faq-slide__title">Everything you need to{' '}know</h2>
      <div className="faq-slide__description">
        Frequently asked questions, along with a{' '}few additional details about the home.
      </div>
      <ul className="faq-slide__list">
        {faq.map((item, index) => (
          <li className="faq-slide__item" key={item.title}>
            <a href="#" className="faq-slide__item-opener" onClick={(e) => handleActiveIndexChange(e, index)}>
              <div className="faq-slide__text">{item.title}</div>
              <div
                className={classNames('faq-slide__icon-box', {
                  'faq-slide__icon-box--active': isActive && faqActiveIndex.includes(index),
                })}
              >
                <SvgIcon name="plus" className="faq-slide__icon" />
              </div>
            </a>
            <AnimateHeight duration={350} height={isActive && faqActiveIndex.includes(index) ? 'auto' : 0}>
              <div className="faq-slide__answer">{item.body}</div>
            </AnimateHeight>
          </li>
        ))}
      </ul>
    </section>
  );
};

type Props = {
  faq: readonly Faq[];
  isActive?: boolean;
};

export default FaqSlide;
