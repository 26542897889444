/* 🤖 this file was generated by svg-to-ts*/
export const svgIcon = {
  back:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M14.53 16.53a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06l4-4a.75.75 0 111.06 1.06L11.06 12l3.47 3.47a.75.75 0 010 1.06z"/></svg>',
  mute:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M10.75 6.017a.25.25 0 00-.411-.191l-3.98 3.371a.75.75 0 01-.969-1.144L9.37 4.682c1.138-.965 2.881-.155 2.881 1.335v11.965c0 1.49-1.744 2.3-2.881 1.335l-3.979-3.37a.75.75 0 01.97-1.145l3.979 3.37a.25.25 0 00.411-.19V6.017z"/><path d="M3.5 9.375a.25.25 0 00-.25.25v4.75c0 .138.112.25.25.25h2.375a.75.75 0 010 1.5H3.5a1.75 1.75 0 01-1.75-1.75v-4.75c0-.966.784-1.75 1.75-1.75h2.375a.75.75 0 010 1.5H3.5zM21.11 9.16a.75.75 0 010 1.06l-4.5 4.5a.75.75 0 01-1.06-1.06l4.5-4.5a.75.75 0 011.06 0z"/><path d="M15.55 9.16a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-4.5-4.5a.75.75 0 010-1.06z"/></svg>',
  plus:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M17.5 11.5h-5v-5A.5.5 0 0012 6a.5.5 0 00-.5.5v5h-5a.5.5 0 00-.5.5.5.5 0 00.5.5h5v5a.5.5 0 001 0v-5h5a.5.5 0 000-1z"/></svg>',
  spinner:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M13 3a1 1 0 00-2 0v4a1 1 0 002 0V3z"/><path opacity=".35" d="M13 17a1 1 0 00-2 0v4a1 1 0 002 0v-4zM21 13a1 1 0 000-2h-4a1 1 0 000 2h4z"/><path d="M7 13a1 1 0 000-2H3a1 1 0 000 2h4zM19.071 6.343a1 1 0 10-1.414-1.414l-2.828 2.828a1 1 0 101.414 1.414l2.828-2.828zM9.172 16.243a1 1 0 10-1.414-1.414L4.93 17.657a1 1 0 101.414 1.414l2.828-2.828z"/><path opacity=".35" d="M17.657 19.071a1 1 0 101.414-1.414l-2.828-2.828a1 1 0 10-1.414 1.414l2.828 2.828z"/><path d="M7.757 9.172a1 1 0 101.414-1.414A3771.43 3771.43 0 006.343 4.93a1 1 0 10-1.414 1.414c.212.213 2.616 2.616 2.828 2.83z"/></svg>',
  'star-empty':
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.755l4.045 2.441-1.074-4.601 3.574-3.096-4.702-.399L12 5.75l-1.843 4.35-4.702.399 3.574 3.096-1.074 4.601L12 15.755zM7.031 21.09c-.784.473-1.751-.23-1.543-1.121l1.319-5.653-4.391-3.804c-.692-.599-.322-1.736.59-1.813l5.78-.49 2.261-5.335c.357-.841 1.549-.841 1.906 0l2.261 5.335 5.78.49c.912.077 1.282 1.214.59 1.813l-4.391 3.804 1.319 5.653c.208.891-.759 1.594-1.543 1.121L12 18.091 7.031 21.09z"/></svg>',
  'star-full':
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M12 18.091l4.969 2.999c.784.473 1.751-.23 1.543-1.121l-1.319-5.653 4.391-3.804c.692-.599.322-1.736-.59-1.813l-5.78-.49-2.261-5.335c-.357-.841-1.549-.841-1.906 0L8.786 8.209l-5.78.49c-.912.077-1.282 1.214-.59 1.813l4.391 3.804-1.319 5.653c-.208.891.759 1.594 1.543 1.121L12 18.091z"/></svg>',
  'star-half':
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M20.994 8.699l-5.78-.49-2.261-5.335c-.357-.841-1.549-.841-1.906 0L8.786 8.209l-5.78.49c-.912.077-1.282 1.214-.59 1.813l4.391 3.804-1.319 5.653c-.208.891.759 1.594 1.543 1.121L12 18.091l4.969 2.999c.784.473 1.751-.23 1.543-1.121l-1.319-5.653 4.391-3.804c.692-.599.322-1.736-.59-1.813zm-5.111 4.105l-.912.79.274 1.176.799 3.426-3.011-1.817L12 15.755V5.751l1.373 3.239.471 1.111 1.202.102 3.5.297-2.663 2.304z"/></svg>',
  unmute:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M9.369 4.682c1.137-.965 2.881-.155 2.881 1.335v11.966c0 1.49-1.743 2.3-2.881 1.335L5.6 16.125H3.5a1.75 1.75 0 01-1.75-1.75v-4.75c0-.966.784-1.75 1.75-1.75h2.1l3.769-3.193zm1.381 1.335a.25.25 0 00-.41-.191L6.36 9.197a.75.75 0 01-.485.178H3.5a.25.25 0 00-.25.25v4.75c0 .138.112.25.25.25h2.375a.75.75 0 01.485.178l3.979 3.37a.25.25 0 00.411-.19V6.017zM14.902 7.623a.75.75 0 011.034-.236c1.416.89 2.314 2.656 2.314 4.618 0 1.961-.898 3.728-2.317 4.61a.75.75 0 01-.792-1.274c.905-.562 1.609-1.799 1.609-3.336 0-1.536-.704-2.777-1.612-3.348a.75.75 0 01-.236-1.034z"/><path d="M17.93 4.448a.75.75 0 011.058-.081c1.995 1.711 3.262 4.51 3.262 7.636 0 3.122-1.265 5.919-3.256 7.63a.75.75 0 01-.978-1.137c1.625-1.397 2.734-3.76 2.734-6.493 0-2.736-1.11-5.101-2.738-6.498a.75.75 0 01-.081-1.057z"/></svg>'
};
