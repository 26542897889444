import * as amplitude from '@amplitude/analytics-browser';

const setupAnalytics = () => {
  if (process.env.REACT_APP_AMPLITUDE_ENV === 'disabled') {
    console.warn('Analytics disabled. Skipping analytics setup.');
    return;
  }

  if (!process.env.REACT_APP_AMPLITUDE_KEY) {
    console.warn('No analytics key found. Skipping analytics setup.');
    return;
  }

  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, undefined, {
    defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
  });
};

export const getAnalyticsInstance = () => {
  const userId = window.localStorage.getItem('userId') ?? '';
  amplitude.setUserId(userId);

  return amplitude;
};

export const trackEvent = (eventName: string, eventProperties: Record<string, unknown> | undefined = undefined) => {
  if (process.env.REACT_APP_AMPLITUDE_ENV === 'disabled') {
    console.warn(`Got event "${eventName}". Analytics disabled. Skipping event tracking.`);
    return;
  }

  const analytics = getAnalyticsInstance();
  analytics.track(eventName, {
    env: process.env.REACT_APP_AMPLITUDE_ENV,
    ...(eventProperties ?? {}),
  });
};

export default setupAnalytics;
