import React, { useState, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';
import VideoFrame from '../VideoFrame/VideoFrame';
import FaqSlide from '../FaqSlide/FaqSlide';
import GetYourGuideSlide from '../GetYourGuideSlide/GetYourGuideSlide';

import { FrameType } from '../../../../../shared/models/frameType';
import { FramePosition } from '../../../../../shared/models/framePosition';
import { InstallationDetailsDto } from '../../../../../shared/models/installationDetailsDto';

import './frame-box.scss';

const DELAY_BEFORE_MAKE_ACTIVE = 200;
const ANIMATION_TIMING = 250;

const FrameBox = ({ frameType, initialPosition, data, onClose }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [isVideoMuted, setIsVideMuted] = useState(false);
  const currentTimeRef = useRef(0);

  useLayoutEffect(() => {
    if (frameType === undefined) {
      setIsActive(false);
      return;
    }
    setTimeout(() => {
      setIsActive(true);
    }, DELAY_BEFORE_MAKE_ACTIVE);
  }, [frameType]);

  const closeFrame = () => {
    setIsActive(false);
    setTimeout(() => {
      onClose(currentTimeRef.current);
    }, ANIMATION_TIMING);
  };

  const toggleMute = () => {
    setIsVideMuted((state) => !state);
  };

  const updateCurrentTime = (currentTime: number) => {
    // eslint-disable-next-line functional/immutable-data
    currentTimeRef.current = currentTime;
  };

  return (
    <div
      className={classNames('frame-box', {
        'frame-box--active': isActive,
      })}
      style={{
        top: initialPosition.top,
        left: initialPosition.left,
        width: initialPosition.width,
        height: initialPosition.height,
      }}
    >
      <section
        className={classNames('frame-box__header', {
          'frame-box__header--shown': isActive,
        })}
      >
        <button className="frame-box__back-btn" type="button" onClick={closeFrame}>
          <SvgIcon name="back" className="frame-box__button-icon" />
          Back
        </button>
        {frameType === 'video' && (
          <button className="frame-box__back-btn frame-box__back-btn--right" type="button" onClick={toggleMute}>
            <SvgIcon name={isVideoMuted ? 'unmute' : 'mute'} className="frame-box__button-icon" />
            {isVideoMuted ? 'Unmute' : 'Mute'}
          </button>
        )}
      </section>
      <section className="frame-box__body">
        {frameType === 'video' && (
          <VideoFrame url={data.home_screen.media_url} muted={isVideoMuted} onUpdateCurrentTime={updateCurrentTime} />
        )}
        {frameType === 'faq' && <FaqSlide faq={data.faq} isActive />}
        {frameType === 'gyg' && <GetYourGuideSlide city={data.city} isActive />}
      </section>
    </div>
  );
};

type Props = {
  frameType: FrameType | undefined;
  initialPosition: FramePosition;
  data: InstallationDetailsDto;
  onClose: (currentVideoTime?: number) => void;
};

export default FrameBox;
