import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import SvgIcon, { IconNames } from '../SvgIcon/SvgIcon';

import './star-rating.scss';

const empty = 'star-empty';
const half = 'star-half';
const full = 'star-full';

const StarRating = ({ value, className }: Props) => {
  const [rate, setRate] = useState<readonly IconNames[]>([empty, empty, empty, empty, empty]);
  useEffect(() => {
    setRate([
      // eslint-disable-next-line
            value < 0.5 ? empty : value < 1 ? half : full,
      // eslint-disable-next-line
            value < 1.5 ? empty : value < 2 ? half : full,
      // eslint-disable-next-line
            value < 2.5 ? empty : value < 3 ? half : full,
      // eslint-disable-next-line
            value < 3.5 ? empty : value < 4 ? half : full,
      // eslint-disable-next-line
            value < 4.5 ? empty : value < 5 ? half : full,
    ]);
  }, [value]);

  return (
    <ul className={classNames('star-rating', className)}>
      {rate.map((item, index) => (
        <li key={item + index} className="star-rating__item">
          <SvgIcon
            name={item}
            className={classNames('star-rating__icon', {
              'star-rating__icon_poor': value === 0,
            })}
          />
        </li>
      ))}
    </ul>
  );
};

type Props = {
  value: number;
  className?: string;
};

export default StarRating;
