import React from 'react';
import classNames from 'classnames';

import './splash-screen.scss';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';

const SplashScreen = ({ isShownSpinner, isShown }: Props) => {
  return (
    <aside
      className={classNames('splash-screen__box', {
        'splash-screen__box--hidden': !isShown,
      })}
    >
      <section className="splash-screen">
        <img src="/images/logo.png" alt="Home guide" className="splash-screen__logo" width={196} height={70} />
        <SvgIcon
          name="spinner"
          className={classNames('splash-screen__spinner', {
            'splash-screen__spinner--shown': isShownSpinner,
          })}
        />
        <div className="splash-screen__alcove-label alcove-label">by Alcove</div>
      </section>
    </aside>
  );
};

type Props = {
  isShownSpinner: boolean;
  isShown: boolean;
};

export default SplashScreen;
