import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WelcomeScreen from './features/welcome-screen/pages/WelcomeScreen/WelcomeScreen';
import NotFound from './features/not-found/pages/NotFound/NotFound';
import EmptyQrCode from './features/empty-qr-code/EmptyQrCode/EmptyQrCode';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EmptyQrCode />} />
      <Route path="/:guid" element={<WelcomeScreen />} />
      <Route path="/:guid/video" element={<WelcomeScreen activatedUrl="video" />} />
      <Route path="/:guid/faq" element={<WelcomeScreen activatedUrl="faq" />} />
      <Route path="/:guid/gyg" element={<WelcomeScreen activatedUrl="gyg" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
