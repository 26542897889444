import axios from 'axios';
import { InstallationDetailsDto } from '../../models/installationDetailsDto';
import { InstallationPinDto } from '../../models/installationPinDto';
import { hasWelcomeDetails } from '../../guards/hasWelcomeDetails';
import { saveHome } from '../localStorage/saveHome';

export const getData = async (guid: string) => {
  try {
    const response = await axios.post<InstallationDetailsDto | InstallationPinDto>(`/roku/connect`, {
      roku_device_udid: guid,
    });
    if (hasWelcomeDetails(response.data)) {
      saveHome(guid);
    }
    return response.data;
  } catch (err: any) {
    console.warn(err);
    throw new Error('Server response with error when we try to get data');
  }
};
