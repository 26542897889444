import axios from 'axios';

import { GetYourGuideDto, TourDetailsDto } from '../../models/getYourGuideDto';

const ITEM_PER_PAGE = 8;

export const getYourGuidInfo = (() => {
  // eslint-disable-next-line functional/no-let
  let cachedTours: CachedToursData;

  return async (city: string, currentPage = 1, limit = ITEM_PER_PAGE): Promise<CachedToursData> => {
    try {
      if (
        cachedTours &&
        cachedTours.city === city &&
        cachedTours.currentPage >= currentPage &&
        cachedTours.limit === limit &&
        cachedTours.tours &&
        cachedTours.tours.length > 0
      ) {
        return Promise.resolve({ ...cachedTours });
      }
      const response = await axios.get<GetYourGuideDto>(
        `/tours?cnt_language=en&q=${city}&currency=USD&limit=${limit}&offset=${currentPage * limit}`,
        {
          baseURL: `${process.env.REACT_APP_GET_YOUR_GUIDE_API_URL}/${process.env.REACT_APP_GET_YOUR_GUIDE_API_VERSION}`,
          headers: {
            'X-ACCESS-TOKEN': process.env.REACT_APP_GET_YOUR_GUIDE_API_KEY,
          },
        },
      );
      cachedTours = {
        city,
        limit,
        currentPage,
        tours: currentPage > 1 ? [...cachedTours.tours, ...response.data.data.tours] : response.data.data.tours,
        totalCount: response.data._metadata.totalCount,
      };
      return Promise.resolve({ ...cachedTours });
    } catch (err: any) {
      console.warn(err);
      throw new Error('Server response with error when we try to get data');
    }
  };
})();

export type CachedToursData = {
  city: string;
  limit: number;
  currentPage: number;
  tours: readonly TourDetailsDto[];
  totalCount: number;
};
