import React from 'react';
import { useNavigate } from 'react-router';
import { useMount } from '../../../../shared/hooks/useMount';

import { getLastHomeGuid } from '../../../../shared/utils/localStorage/getLastHomeGuid';

import './empty-qr-code.scss';

const EmptyQrCode = () => {
  const navigate = useNavigate();
  useMount(() => {
    const lastHomeGuid = getLastHomeGuid();
    if (!lastHomeGuid) {
      return;
    }
    navigate(`/${lastHomeGuid}`);
  });
  return (
    <main className="empty-qr-code__box">
      <section className="empty-qr-code">
        <img src="/images/logo.png" alt="Home guide" className="empty-qr-code__logo" width={196} height={70} />
        <img
          src="/images/scan-qr-code.png"
          className="empty-qr-code__scan-decor"
          alt="Scan QR code by your phone"
          width={192}
          height={200}
        />
        <div className="empty-qr-code__text">To continue, find and scan the QR code posted by the home owner</div>
        <div className="empty-qr-code__alcove-label alcove-label">by Alcove</div>
      </section>
    </main>
  );
};

export default EmptyQrCode;
