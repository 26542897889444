import React, { useRef } from 'react';

import './video-frame.scss';

const VideoFrame = ({ url, muted, onUpdateCurrentTime }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const updateProgress = () => {
    if (!videoRef.current) {
      return;
    }
    const { currentTime } = videoRef.current;
    onUpdateCurrentTime(currentTime);
  };

  return (
    <section className="video-frame">
      <video
        ref={videoRef}
        disablePictureInPicture
        disableRemotePlayback
        className="video-frame__video"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        autoPlay
        loop
        playsInline
        controls
        muted={muted}
        onProgress={() => {
          updateProgress();
        }}
        onTimeUpdate={(e) => {
          updateProgress();
        }}
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
};

type Props = {
  url: string;
  muted: boolean;
  onUpdateCurrentTime: (currentTime: number) => void;
};

export default VideoFrame;
