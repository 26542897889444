/* eslint-disable no-magic-numbers */
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { useMount } from '../../../../../shared/hooks/useMount';
import { getData } from '../../../../../shared/utils/api/getData';

import SplashScreen from '../../components/SplashScreen/SplashScreen';
import PinScreen from '../../components/PinScreen/PinScreen';
import VideoSlide from '../../components/VideoSlide/VideoSlide';
import FaqSlide from '../../components/FaqSlide/FaqSlide';
import FrameBox from '../../components/FrameBox/FrameBox';
import GetYourGuideSlide from '../../components/GetYourGuideSlide/GetYourGuideSlide';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { InstallationDetailsDto } from '../../../../../shared/models/installationDetailsDto';
import { InstallationPinDto } from '../../../../../shared/models/installationPinDto';
import { hasWelcomeDetails } from '../../../../../shared/guards/hasWelcomeDetails';
import { preloadImage } from '../../../../../shared/utils/preloadImage';
import { FrameType } from '../../../../../shared/models/frameType';
import { FramePosition } from '../../../../../shared/models/framePosition';

import './welcome-screen.scss';
import 'swiper/css';

const MAX_TIME_TO_AWAIT_BACKGROUND_IMAGE_LOADING = 2000;

const WelcomeScreen = ({ activatedUrl }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [componentDataState, setComponentDataState] = useState<ApiInteractionState>();
  const dataRef = useRef<InstallationDetailsDto | InstallationPinDto>();
  const errorRef = useRef<string>();
  const loadingImageTimerRef = useRef<number>();
  const [isSplashScreenShown, setIsSplashScreenShown] = useState(true);
  const [activeFrame, setActiveFrame] = useState<FrameType>();
  const [videoSlideCurrentTime, setVideoSlideCurrentTime] = useState<number>();
  const [initialFramePosition, setInitialFramePosition] = useState<FramePosition>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const videoSlideRef = useRef<HTMLDivElement>(null);
  const faqSlideRef = useRef<HTMLDivElement>(null);
  const getYourGuidSlideRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperClass>();

  useMount(() => {
    if (!params.guid) {
      return;
    }

    loadDataFromServer(params.guid);
  });

  useEffect(() => {
    if (
      componentDataState !== 'LoadedSuccessful' ||
      !dataRef.current ||
      !hasWelcomeDetails(dataRef.current) ||
      !dataRef.current.home_screen.background_url
    ) {
      return;
    }

    preloadImage(dataRef.current.home_screen.background_url, onBackgroundLoaded);
  }, [componentDataState]);

  useEffect(() => {
    if (Boolean(activatedUrl) && activeFrame === undefined) {
      // wrong hash, probable user open slide and press F5
      navigate(-1);
      return;
    }
    if (!activatedUrl) {
      closeFrame();
    }
  }, [location]);

  const loadDataFromServer = async (guid: string) => {
    setComponentDataState('Loading');
    try {
      // eslint-disable-next-line functional/immutable-data
      dataRef.current = await getData(guid);
      setComponentDataState('LoadedSuccessful');
      if (hasWelcomeDetails(dataRef.current)) {
        // eslint-disable-next-line functional/immutable-data
        loadingImageTimerRef.current = window.setTimeout(() => {
          setIsSplashScreenShown(false);
        }, MAX_TIME_TO_AWAIT_BACKGROUND_IMAGE_LOADING);
      } else {
        setIsSplashScreenShown(false);
      }
    } catch (errorMessage) {
      // eslint-disable-next-line functional/immutable-data
      errorRef.current = errorMessage as string;
      setComponentDataState('Error');
    }
  };

  const onBackgroundLoaded = () => {
    setIsSplashScreenShown(false);
    if (loadingImageTimerRef.current) {
      clearTimeout(loadingImageTimerRef.current);
    }
  };

  const openFrame = (frameType: FrameType) => {
    const ref = getActiveFrame(frameType);
    if (!ref || !swiperRef.current) {
      return;
    }
    const currentSlide = swiperRef.current.activeIndex;
    if (frameType === 'faq' && currentSlide !== 1) {
      swiperRef.current.slideTo(1);
      return;
    }
    if (frameType === 'gyg' && currentSlide !== 2) {
      swiperRef.current.slideTo(2);
      return;
    }
    if (frameType === 'video' && currentSlide !== 0) {
      swiperRef.current.slideTo(0);
      return;
    }
    const { top, left, height, width } = ref.getBoundingClientRect();
    setInitialFramePosition({ top, left, width, height });
    setActiveFrame(frameType);
    navigate(`/${params.guid}/${frameType}`);
  };

  const closeFrameAndGoBack = (currentTime?: number) => {
    if (currentTime !== undefined) {
      setVideoSlideCurrentTime(currentTime);
    }
    navigate(-1);
  };

  const closeFrame = () => {
    setActiveFrame(undefined);
  };

  const getActiveFrame = (frameType: FrameType) => {
    if (frameType === 'faq') {
      return faqSlideRef.current;
    }
    if (frameType === 'gyg') {
      return getYourGuidSlideRef.current;
    }
    return videoSlideRef.current;
  };

  return (
    <>
      <SplashScreen isShown={isSplashScreenShown} isShownSpinner={componentDataState === 'Loading'} />
      {componentDataState === 'LoadedSuccessful' && dataRef.current !== undefined && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {hasWelcomeDetails(dataRef.current) ? (
            <main
              className="welcome-screen"
              style={{ backgroundImage: `url(${dataRef.current.home_screen.background_url})` }}
            >
              <h1 className="welcome-screen__title">Welcome to {dataRef.current.city}</h1>
              <section className="welcome-screen__greeting">{dataRef.current.home_screen.caption}</section>
              <section className="welcome-screen__slider-box">
                <Swiper
                  className="welcome-screen__slider"
                  slidesPerView="auto"
                  modules={[Pagination]}
                  spaceBetween={1}
                  pagination
                  grabCursor
                  onSwiper={(swiper: SwiperClass) => {
                    // eslint-disable-next-line functional/immutable-data
                    swiperRef.current = swiper;
                  }}
                  // onSlideChange={() => console.log('slide change')}
                >
                  {Boolean(dataRef.current.home_screen.media_url) && (
                    <SwiperSlide className="welcome-screen__slide">
                      <div
                        className="welcome-screen__slide-content"
                        onClick={() => openFrame('video')}
                        ref={videoSlideRef}
                      >
                        <VideoSlide url={dataRef.current.home_screen.media_url} currentTime={videoSlideCurrentTime} />
                      </div>
                    </SwiperSlide>
                  )}
                  <SwiperSlide className="welcome-screen__slide">
                    <div className="welcome-screen__slide-content" onClick={() => openFrame('faq')} ref={faqSlideRef}>
                      <FaqSlide faq={dataRef.current.faq} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="welcome-screen__slide">
                    <div
                      className="welcome-screen__slide-content"
                      onClick={() => openFrame('gyg')}
                      ref={getYourGuidSlideRef}
                    >
                      <GetYourGuideSlide city={dataRef.current.city} />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </section>
              {Boolean(dataRef.current) && (
                <FrameBox
                  frameType={activeFrame}
                  initialPosition={initialFramePosition}
                  data={dataRef.current}
                  onClose={closeFrameAndGoBack}
                />
              )}
            </main>
          ) : (
            <PinScreen code={dataRef.current.pin} />
          )}
        </>
      )}
    </>
  );
};

type Props = {
  activatedUrl?: FrameType;
};

export default WelcomeScreen;
