import { STORAGE_KEY } from './storageKey';

export const saveHome = (homeGuid: string) => {
  const rawSavedHomes = window.localStorage.getItem(STORAGE_KEY);
  if (!rawSavedHomes) {
    saveHomeToLocalStorage(homeGuid);
    return;
  }
  try {
    const savedHomes = JSON.parse(rawSavedHomes);
    if (!Array.isArray(savedHomes)) {
      saveHomeToLocalStorage(homeGuid);
    }
    if (!savedHomes.includes(homeGuid)) {
      saveHomeToLocalStorage(homeGuid, savedHomes);
    }
  } catch (error) {
    console.warn('Error during saving home guid', error);
    saveHomeToLocalStorage(homeGuid);
  }
};

const saveHomeToLocalStorage = (guid: string, previousHomes: readonly string[] = []) => {
  const newHome = JSON.stringify(previousHomes.length > 0 ? [...previousHomes, guid] : [guid]);
  window.localStorage.setItem(STORAGE_KEY, newHome);
};
