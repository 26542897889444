import React, { useEffect, useRef } from 'react';

import './video-slide.scss';

const VideoSlide = ({ url, currentTime }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (currentTime === undefined || !videoRef.current) {
      return;
    }
    // eslint-disable-next-line
    videoRef.current.currentTime = currentTime - 0.3;
  }, [currentTime]);

  return (
    <section className="video-slide">
      <video
        ref={videoRef}
        disablePictureInPicture
        disableRemotePlayback
        className="video-slide__video"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        muted
        autoPlay
        loop
        playsInline
        preload="auto"
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
};

type Props = {
  url: string;
  currentTime?: number;
};

export default VideoSlide;
