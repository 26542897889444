/* eslint-disable functional/immutable-data */
import axios from 'axios';

const httpConfig = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.responseType = 'json';

  axios.interceptors.request.use((request) => {
    if (request === undefined || request.headers === undefined) {
      return request;
    }
    request.headers['x-alcove-roku-token'] = 'rokuToken';
    request.headers['Content-Type'] = 'application/json';

    return request;
  });
};

export default httpConfig;
